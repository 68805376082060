import Table from "./table/table";
import THead from "./table/THead";
import Tier from "./table/Tier";

const companions = [
    {
        name: "Travain",
        stars: 1,
        tier: "S",
    },
    {
        name: "Sallyhorn",
        stars: 1,
        tier: "S",
    },
    {
        name: "Gryphon",
        stars: 1,
        tier: "S",
    },
    {
        name: "Yivnian",
        stars: 2,
        tier: "A",
    }
]
const CompanionTierList = () => {
    return (
        <div className="m-4">
            <h3>Companion Tier List</h3>
            <Table>
                <THead columns={["Tier", "Stars", "Name"]}/>
                <tbody>
                {companions.map(companion => (
                    <tr className="border-t-2 md:border-t-0" key={companion.name}>
                        <td><Tier tier={companion.tier}/></td>
                        <td>{companion.stars}</td>
                        <td>{companion.name}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <p className="py-2">
                Your first priority is to get the 3 1 star companions to Mythic.
            </p>
            <p className="py-2">
                Some would say that Sallyhorn is more valuable than Travain. I am agnostic on that point.
            </p>
            <p className="py-2">
                After you get the 3 1 star companions to Mythic, you can get the rest of your main team companions to
                mythic or max traits.
            </p>
            <p className="py-2">
                It doesn't matter which companion you choose to upgrade after your one star companions are up to Mythic.
            </p>
            <p className="py-2">
                I did rate Yivnian as A because Yivnian is an excellent M and F, and they are better at Leadership than
                Sallyhorn.
            </p>
        </div>
    )
}

export default CompanionTierList;