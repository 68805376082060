import './App.css';
import Welcome from "./components/welcome";
import NewPlayers from "./components/newplayers";
import ChampionTierList from "./components/championtierlist";
import ComingSoon from "./components/comingsoon";
import CompanionGuide from "./components/companionguide";
import OtherInfo from "./components/otherinfo";
import Interrogations from "./components/Interrogations";
import Resources from "./components/resources";

function App() {
    return (
        <div className="container mx-auto">
            <Welcome/>
            <ComingSoon/>
            <NewPlayers/>
            <CompanionGuide/>
            <ChampionTierList/>
            <Resources/>
            <Interrogations/>
            <OtherInfo/>
        </div>
    );
}

export default App;
