const tierMap = {
    "S": "bg-blue-500",
    "A": "bg-green-500",
    "B": "bg-yellow-500",
    "C": "bg-orange-500",
    "D": "bg-red-500",
    "F": "bg-gray-700",
}
const Tier = ({tier}) => {
    const bg_color = tierMap[tier] || "grey";
    const divStyle = `rounded-full ${bg_color} text-white text-center h-10 w-10 px-1 py-2 mr-2`;
    return (
        <div className={divStyle}><span className="my-auto">{tier}</span></div>
    )
}
export default Tier;