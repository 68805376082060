import CompanionTierList from "./companiontierlist";
import CompanionStrategy from "./companionstrategy";

const CompanionGuide = () => {
    return (
        <div>
            <h2>Companion Guide</h2>
            <CompanionTierList/>
            <CompanionStrategy/>
        </div>
    )
}

export default CompanionGuide;