import Table from "./table/table";
import THead from "./table/THead";

const interrogation_actions = [
    {
        champion: "M Ugrull",
        influence: 1000,
        pacify: "...Pity?!",
        communicate: "...Glory to the Orcs!",
        punish: "...teeth!",
        intimidate: "...honor!",
    },
    {
        champion: "F Ugrull",
        influence: 1000,
        pacify: "...resort to?",
        communicate: "...unreasonable.",
        punish: "...effect on me!",
        intimidate: "...terms!"
    },
    {
        champion: "M Tidestorm",
        influence: 1000,
        pacify: "...time on your hands.",
        communicate: "...It won't work.",
        punish: "...cave",
        intimidate: "...As a Dragonborn...time with me.",
    },
    {
        champion: "F Tidestorm",
        influence: 1000,
        pacify: "...work on me?",
        communicate: "...solve these problems?",
        punish: "...honor with my life!",
        intimidate: "...ulterior motive...plain to see",
    },
    {
        champion: "M Karg",
        influence: 500,
        pacify: "...",
        communicate: "...",
        punish: "...",
        intimidate: "...",
    },
    {
        champion: "F Karg",
        influence: 500,
        pacify: "...refuse",
        communicate: "...discuss...",
        punish: "...Dragon's honor!",
        intimidate: "...convince me",
    },
    {
        champion: "M Gryphon",
        influence: 300,
        pacify: "...",
        communicate: "...",
        punish: "...",
        intimidate: "...",
    },
    {
        champion: "F Gryphon",
        influence: 300,
        pacify: "...you are sincere.",
        communicate: "...offer...worth considering",
        punish: "This pain...knees!",
        intimidate: "...fruitless. Give up.",
    },
]

const Interrogations = () => {
    return (
        <div>
            <h3>Interrogations</h3>
            <p>While you are collecting, you will occassionally come across an enemy for your followers to pursue. If
                they catch up to the enemy they will capture them, where they go to your dungeon.</p>
            <p>In the dungeon you can interrogate them, gaining either 20 or 30 influence depending on if you choose the
                "right" option.</p>
            <p>Once you reach 300 (for 1 star champions), 500 (for 2 star champions), or 1000 (for 3 star champions) you
                can recruit them, where they will join your champions as a common.</p>
            <p>In my opinion, this and exploration are the best sources of commons in the game, which are useful when
                marrying.</p>
            <Table>
                <THead columns={["Champion", "Pacify", "Communicate", "Punish", "Intimidate"]}/>
                <tbody>
                {interrogation_actions.map(action => (
                    <tr className="border-t-2 md:border-t-0" key={action.champion}>
                        <td>{action.champion}</td>
                        <td>{action.pacify}</td>
                        <td>{action.communicate}</td>
                        <td>{action.punish}</td>
                        <td>{action.intimidate}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <p>Don't spend gems on opening cells, you will open all 5 within a couple weeks.</p>
            <p>Also, spend the interrogation scrolls liberally. You will be overrun with them.</p>
        </div>
    )
}

export default Interrogations;