import Table from "./table/table";
import THead from "./table/THead";

const companion_intimacy = [
    {
        stars: 1,
        common: 0,
        rare: 101,
        epic: 501,
        legendary: 2001,
        mythic: 5001,
        up1: 8801,
        up2: 11601,
        up3: 14401,
        up4: 17201,
        max: 20000,
    },
    {
        stars: 2,
        common: 0,
        rare: 151,
        epic: 751,
        legendary: 3001,
        mythic: 7501,
        up1: "13200*",
        up2: "17400*",
        up3: "21600*",
        up4: "25800*",
        max: "30000*",
    },
    {
        stars: 3,
        common: 0,
        rare: 201,
        epic: 1001,
        legendary: 4001,
        mythic: 10001,
        up1: "17600*",
        up2: "23200*",
        up3: "28800*",
        up4: "34400*",
        max: 40000,
    },
]

const CompanionStrategy = () => {
    return (
        <div>
            <h3>Companion Strategy</h3>
            <p>
                Things to know about companions:
            </p>
            <ul className="pl-4 list-disc list-outside">
                <li>
                    The lower the stars of a companion the more valuable it is. This is because the wait time on
                    producing heirs is lower, and the faster you can raise intimacy.
                </li>
                <li>
                    You can only be an ancestor one time in each champions family tree.
                </li>
                <li>
                    This means that if you produce an heir, they, nor their children, can marry any of your other heirs.
                </li>
                <li>
                    The levels of % gain traits are the biggest indicators of combat potential. Companion intimacy is
                    the easiest way to get upgraded traits.
                </li>
                <li>As you increase intimacy past Mythic tier, you will begin to gain higher level traits, up to level
                    22.
                </li>
                <li>
                    It is fairly accepted that getting the 3 1 star companions to Mythic is your first priority.
                </li>
                <li>
                    There is substantial debate around whether it is more valuable to get 1 companion to Max Traits, or
                    to get all of your main team companions to mythic.
                </li>
                <li>
                    Personally, I lean towards getting one of your main team companion to max traits, because it will
                    make investments in them much more effective.
                </li>
            </ul>
            <Table>
                <THead
                    columns={["Stars", "Rare", "Epic", "Legendary", "Mythic", "lvl 19 traits", "level 20 traits", "level 21 traits", "level 22 traits"]}/>
                <tbody>
                {companion_intimacy.map(tier => (
                    <tr className="border-t-2 md:border-t-0" key={tier.stars}>
                        <td>{tier.stars} stars</td>
                        <td>{tier.rare}</td>
                        <td>{tier.epic}</td>
                        <td>{tier.legendary}</td>
                        <td>{tier.mythic}</td>
                        <td>{tier.up1}</td>
                        <td>{tier.up2}</td>
                        <td>{tier.up3}</td>
                        <td>{tier.up4}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <p>* means it is a best guess. Not confirmed.</p>
        </div>
    )
}

export default CompanionStrategy;