import Table from "./table/table";
import THead from "./table/THead";
import Tier from "./table/Tier";

const champion_tier_list = [
    {
        overall_tier: "S",
        campaign_tier: "S",
        pvp_tier: "S",
        raid_tier: "A",
        clan: "Lionstone",
        gender: "F",
        reasons: "The buff she provides allies is extremely powerful.",
    },
    {
        overall_tier: "A",
        campaign_tier: "A",
        pvp_tier: "S",
        raid_tier: "B",
        clan: "Lionstone",
        gender: "M",
        reasons: "The male Lionstone is a fantastic tank, however he lacks the buffing power of his female counterpart.",
    },
    {
        overall_tier: "S",
        campaign_tier: "A",
        pvp_tier: "S",
        raid_tier: "S",
        clan: "The Luxuriant",
        gender: "F",
        reasons: "The Luxuriant female's charm is one of the best abilities in the game. It can turn a game around.",
    },
    {
        overall_tier: "B",
        campaign_tier: "B",
        pvp_tier: "B",
        raid_tier: "D",
        clan: "The Luxuriant",
        gender: "M",
        reasons: "The Luxuriant male's banish is situationally useful at best.",
    },
    {
        overall_tier: "A",
        campaign_tier: "A",
        pvp_tier: "A",
        raid_tier: "A",
        clan: "Tidestorm",
        gender: "M",
        reasons: "The damage output of the male Tidestorm is the best of the marksmen. While lacking the raw damage output of some of the mages, his damage and his stun land him in this tier.",
    },
    {
        overall_tier: "A",
        campaign_tier: "B",
        pvp_tier: "A",
        raid_tier: "A",
        clan: "Tidestorm",
        gender: "F",
        reasons: "The damage output of the female Tidestorm is marginally lower than the male's, but it adds additional utility and protection that makes up for it, particularly in Raids.",
    },
    {
        overall_tier: "S",
        campaign_tier: "S",
        pvp_tier: "S",
        raid_tier: "B",
        clan: "Ignis",
        gender: "F",
        reasons: "The female Ignis is a great allie due to dealing massive multi-target damage. Pair with the female Lionstone for some massive chunking.",
    },
    {
        overall_tier: "B",
        campaign_tier: "B",
        pvp_tier: "A",
        raid_tier: "B",
        clan: "Ignis",
        gender: "M",
        reasons: "The male Ignis is really only useful while the female Ignis is on the same team due to stacking and consuming the burning condition.",
    },
    {
        overall_tier: "S",
        campaign_tier: "S",
        pvp_tier: "S",
        raid_tier: "B",
        clan: "Fulgur",
        gender: "F",
        reasons: "Fantastic damage output and decent buffs. Best when paired with the male Fulgur.",
    },
    {
        overall_tier: "S",
        campaign_tier: "S",
        pvp_tier: "S",
        raid_tier: "B",
        clan: "Fulgur",
        gender: "M",
        reasons: "Fantastic damage output and decent buffs. Best when paired with the female Fulgur.",
    },
    {
        overall_tier: "A",
        campaign_tier: "C",
        pvp_tier: "C",
        raid_tier: "S",
        clan: "Travain",
        gender: "F",
        reasons: "The female Travain has 1 purpose: % max health true damage on hit, which is fantastic in Raids. She should be on every raid team.",
    },
    {
        overall_tier: "C",
        campaign_tier: "C",
        pvp_tier: "C",
        raid_tier: "C",
        clan: "Travain",
        gender: "M",
        reasons: "Generally outclassed around every corner.",
    },
    {
        overall_tier: "S",
        campaign_tier: "S",
        pvp_tier: "S",
        raid_tier: "B",
        clan: "Yivnian",
        gender: "F",
        reasons: "Great damage and able to focus low health enemies.",
    },
    {
        overall_tier: "A",
        campaign_tier: "A",
        pvp_tier: "A",
        raid_tier: "A",
        clan: "Yivnian",
        gender: "M",
        reasons: "His ability to drain energy makes him situationally useful. Great for raids and for disrupting enemies before they ult.",
    },
    {
        overall_tier: "",
        campaign_tier: "",
        pvp_tier: "",
        raid_tier: "",
        clan: "",
        gender: "",
        reasons: "",
    },
]
const ChampionTierList = () => {
    return (
        <div className="m-4">
            <h2>Champion Tier List</h2>
            <Table>
                <THead
                    columns={["Overall Tier", "Campaign Tier", "PvP Tier", "Raid Tier", "Clan", "Gender", "Reasons"]}/>
                <tbody>
                {champion_tier_list.map(champion => (
                    <tr className="border-t-2 md:border-t-0" key={champion.clan + champion.gender}>
                        <td><Tier tier={champion.overall_tier}/></td>
                        <td><Tier tier={champion.campaign_tier}/></td>
                        <td><Tier tier={champion.pvp_tier}/></td>
                        <td><Tier tier={champion.raid_tier}/></td>
                        <td>{champion.clan}</td>
                        <td>{champion.gender}</td>
                        <td>{champion.reasons}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <p>
                More coming soon...
            </p>
        </div>
    )
}

export default ChampionTierList;