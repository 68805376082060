const OtherInfo = () => {
    return (
        <div>
            <h3>Other Info</h3>
            <p>
                Some other useful tidbits of information:
            </p>
            <ul className="pl-4 list-disc list-outside">
                <li>
                    If you have a lower rarity champion that you have ascended past 5 stars, you can raise it's rarity
                    (aka turn a common into a mythic) by feeding it a mythic copy.
                </li>
                <li>
                    When you raise the champion's rarity, the clan trait will increase in level, but none of the other
                    traits will. If increasing rarity increases it's number of traits (e.g. going from common to
                    legendary you go from 1 trait to 4), the new traits will be at the new rarity's level.
                </li>
                <li>
                    Challenges rotate on a schedule. They last 4 days and rotate between Might -> Arena & Heir -> Charm
                    & Districts -> Intimacy -> Might -> ... and so on. Generally you want to save the resources
                    associated with those challenges for those challenges (for example don't spend arena tickets except
                    during the arena event).
                </li>
                <li>
                    Once you reach 48 million power, legendary equipment becomes available for drops from Senate.
                </li>
                <li>
                    Once you reach 240 million power, mythic equipment becomes available for drops from Senate.
                </li>
            </ul>
        </div>
    )
}

export default OtherInfo;