const THead = ({columns}) => {
    return (
        <thead>
        <tr className="text-left">
            {columns.map(column => (
                <th className="px-1" key={column}>{column}</th>
            ))}
        </tr>
        </thead>
    )
}
export default THead;