const NewPlayers = () => {
    return (
        <div className="p-4">
            <h2>New Players Start Here</h2>
            <p>
                The first thing you should do is to decide how long you are going to play this game.
            </p>
            <div className="m-4">
                <p>Advice for players of every level:</p>
                <ul className="pl-4 list-disc list-outside">
                    <li>Spend your gold and skill books on any champion. You can always reset them for free as often as
                        you like.
                    </li>
                    <li>Star with a team of 5, eventually working up to 3 teams of 5.</li>
                    <li>Don't upgrade any champion with rarity of Rare or less past 4 stars.</li>
                    <li>Traits are the most useful indicator of the strength of a champion. Also % bonus traits are far
                        better than flat bonus traits.
                    </li>
                    <li>Join the <a className="text-blue-500 underline" href="https://discord.gg/FdvtGPKs8b">official
                        discord</a> to see lots of great
                        recommendations on tier lists, answers from moderators, and more.
                    </li>
                </ul>
            </div>
            <div className="m-4">
                <p>For players just trying it out (spend less than 1 month in the game):</p>
                <ul className="pl-4 list-disc list-outside">
                    <li>Spending gems exclusively on summons is a strong approach. If you are active you might try to
                        chase the Challenge's leaderboard (just don't get the summoning value packs)
                    </li>
                    <li>The things that will be most fun are gaining mythic champions and competing in the challenges
                        each week.
                    </li>
                    <li>Summoning will be the fastest way to gain a spread of mythic champions (approx. 1 mythic per 8k
                        gems). You gain gems very quickly early on.
                    </li>
                </ul>
            </div>
            <div className="m-4">
                <p>For players who are committing to spending multiple months playing the game:</p>
                <ul className="pl-4 list-disc list-outside">
                    <li>You need a mix of summoning and producing mythic heirs. The longer you will play the game the
                        more worthwhile it is to invest in companions.
                    </li>
                    <li>The companion value packs (599 gems) give you ~350 Intimacy each. If you are active, it's
                        reasonable to get 700-1200 gems/day. See the section on companions below.
                    </li>
                    <li>
                        Wait to spend your bouquets until you have companions at Mythic.
                    </li>
                </ul>
            </div>
            <div className="m-4">
                <p>Best guesses for player's who want to spend over a year in the game:</p>
                <ul className="pl-4 list-disc list-outside">
                    <li>You will want to do a little summoning just to get a basic team put together. Shoot for a team
                        of 3-5.
                    </li>
                    <li>
                        Wait to spend your bouquets until you have companions at max intimacy (for level 22 traits).
                    </li>
                    <li>Then focus heavily on companions. See the companion section.</li>
                </ul>
            </div>
        </div>
    )
}

export default NewPlayers;