const ComingSoon = () => {
    return (
        <div className="m-4">
            <h2>Coming Soon</h2>
            <div className="m-4">
                <ul className="pl-4 list-disc list-outside">
                    <li>
                        Basic Styling and routing
                    </li>
                    <li>
                        Guides for the various features of the game
                    </li>
                    <li>
                        Tier List completion
                    </li>
                    <li>
                        Tier List feedback forms
                    </li>
                    <li>
                        Champion Trait Recommendor
                    </li>
                    <li>
                        Champion Imports
                    </li>
                    <li>
                        Marriage Recommendor
                    </li>
                    <li>
                        Detailed Multi-generational Marriage Planning
                    </li>
                </ul>
            </div>
            {/*<Patreon/>*/}
        </div>
    )
}

export default ComingSoon;