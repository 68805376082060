const Resources = () => {
    return (
        <div>
            <h3>Spending Resources</h3>
            <p>
                This is not designed to be a comprehensive list, nor is it the end-all-be-all. There is flexibility in
                this game and decisions on how to spend resources when, as in any strategy game. Hopefully this guide
                takes away some fear/uncertainty for new players, and provides ideas for veteran players.
            </p>
            <ul className='pl-4 list-disc list-outside'>
                <li>
                    <span className='font-bold'>Gold</span>: Trade-off. You can upgrade your champions as you go, or
                    save up for the might challenge.
                </li>
                <li>
                    <span className="font-bold">Food</span>: Trade-off. You can spend as you get it for a gold boost, or
                    you can wait for the district challenge.
                </li>
                <li>
                    <span className="font-bold">Gems</span>: Numerous options. Personally I focus on companion intimacy
                    value packs. See New Players Start Here.
                </li>
                <li>
                    <span className="font-bold">Skill Books</span>: Spend at will. Fully refundable on reset. Minor
                    power boost.
                </li>
                <li>
                    <span className="font-bold">Ingredients for banquets</span>: No point in waiting afaik.
                </li>
                <li>
                    <span className="font-bold">Gold Chests</span>: You get these a lot. If you are struggling to pass a
                    key campaign stage (for example opening a new district or unlocking the a challenge fund tier),
                    spending some gold chests will help you increase your team's power. If not, the best time to play
                    this is during the might challenge. Also, be sure to make any available upgrades to your districts
                    that you want to.
                </li>
                <li>
                    <span className="font-bold">Arena Tickets</span>: These are more uncommon, and should always be
                    saved for the arena challenge.
                </li>
                <li>
                    <span className="font-bold">Charm items</span>: Spend only during the Charm challenge. Spread out
                    evenly between companions for district boost.
                </li>
                <li>
                    <span className="font-bold">Intimacy items</span>: Spend only during the Intimacy challenge. Focus
                    on 1 star companions intially (see Companion Section).
                </li>
                <li>
                    <span className="font-bold">Interrogation Scrolls</span>: Use them at will.
                </li>
                <li>
                    <span className="font-bold">Energy Potions (Heir)</span>: Generally save these for Heir
                    challenge and for late game when you will be producing 3 star mythics.
                </li>
                <li>
                    <span className="font-bold">Vigor Potions (Carouse)</span>: Trade-off. Spend them early to
                    gain a competitive boost to your gold. Spend them late to get substantially more gold per potion.
                </li>
                <li>
                    <span className="font-bold">Stamina Potions (Expedition)</span>: Can provide a minor power boost to
                    your might. There are occassional events where you need to obtain champions of various classes at
                    specific low-star levels. These potions would be very effective at that time. Also late game, once
                    the Hall of Fame starts filling, there will occassionally be quests in there for doing X
                    expeditions. These potions are much rarer late game.
                </li>
                <li>
                    <span className="font-bold">Wedding Rings</span>: Late late late late game. In my opinion these are
                    one of the most precious resources and should be used sparingly before you start getting level 22
                    traits.
                </li>
                <li>
                    <span className="font-bold">Equipment tokens</span>: Plentiful. If you upgrade a common piece of
                    equipment, then feed it to rare equipment, all that leveling will pass on.
                </li>
                <li>
                    <span className="font-bold">Senate Orders</span>: You get a lot of these. My strategy is to use on
                    food early on, then equipment after my power reaches 240 million for a chance at mythic armor. I may
                    decide to just always pull food.
                </li>
            </ul>
        </div>
    )
}

export default Resources;